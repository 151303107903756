"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TreeList_vue_1 = require("@views/admin/tree/TreeList.vue");
var TreeCreate_vue_1 = require("@views/admin/tree/TreeCreate.vue");
var TreeEdit_vue_1 = require("@views/admin/tree/TreeEdit.vue");
exports.default = [
    {
        path: '/admin/category-trees',
        name: 'Tree List',
        components: { default: TreeList_vue_1.default }
    },
    {
        path: '/admin/category-trees/create',
        name: 'Tree Item Create',
        components: { default: TreeCreate_vue_1.default }
    },
    {
        path: '/admin/category-trees/:id',
        name: 'Tree Item Edit',
        components: { default: TreeEdit_vue_1.default }
    },
];
